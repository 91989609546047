import AdminHeader from "../../component/Header"
import Sidebar from "../../component/Sidebar"
import { ToastContainer, toast } from 'react-toastify';
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import React, { useRef, useState, useEffect } from "react";
import constant from "../../component/Services/constant";
const AddBlogsCategory = () => {
    let apiServices = new ApiService();
    const didMountRef = useRef(true)
    const [catImage, setcatImage] = useState('')
    const [edititemdata, setedititemdata] = useState({})
    const [catId, setcatId] = useState('')
    const [blogcategory, setblogcategory] = useState([])
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [parentCategory, setparentcategory] = useState()
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();
    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('cat_name', data.cat_name);
        if (parentCategory && parentCategory !== '') {
            formData.append('parent_cat_id', parentCategory);
        }
        if (catImage) {
            formData.append('cat_img', catImage);
        }
        if (catId !== '') {
            formData.append('cat_id', catId);
        }
    
        apiServices.getnewsblogcategoryrequest(formData)
            .then(res => {
                if (res.data.status === "success") {
                    toast.success(res?.data?.message);
                    getcategorylist();
                   setcatImage('')
                    setValue("cat_image", '');
                    setImage({ preview: "", raw: "" })
                    setValue("cat_name", '');
                    setValue("parent_cat_id", '');
                    setedititemdata({})
                    setcatId('')
                   setparentcategory('')
                }
                else if (res.data.status === "error"){
                
                    toast.error(res?.data?.message);
                }
            })
            .catch(error => {
                console.error('Error submitting form:', error);
                // Handle error
            });
    };

    

    const changestatus=(data , statuscode)=>{
        const requestBody = {
            cat_name: data.cat_name,
            parent_cat_id: data.parent_cat_id !== undefined && data.parent_cat_id !== '' ? data.parent_cat_id : 0,
            cat_status:data.cat_status==1?0:data.cat_status==0?1:1,
            cat_id:data._id,
             cat_img: data.cat_img,
        };
        apiServices.getnewsblogcategoryrequest(requestBody).then(res => {
            if (res.data.status === "success") {
                toast.success(res?.data?.message);
                getcategorylist()
                setValue("cat_name", '');
                setcatImage('')
                setparentcategory('')
            }
        }).catch(error => {
            console.error('Error submitting form:', error);
            // Handle error
        });
    }

   
    useEffect(() => {

        if (didMountRef.current) {
            getcategorylist()
        }
        didMountRef.current = false;
    },[])

    useEffect(()=>{
        if(edititemdata){
            setValue("cat_name", edititemdata?.cat_name);
            setcatImage(edititemdata.cat_img)
            setparentcategory(edititemdata?.parent_cat_id )
        }
    },[edititemdata])

    const getcategorylist = () => {
        apiServices.getnewsblogcategorylistrequest().then(res => {
            if (res?.data?.status == "success") {
                setblogcategory(res.data.categories)
            }
        }).catch((error)=>{})
    }


    const removecategory = (itemid) => {
        const dataString = {
            _id: itemid
        }
        apiServices.getnewsblogsdeletecatrequest(dataString).then((res) => {
            if (res?.data?.status == "success") {
                toast.success("Category has been Deleted successfully")
                setTimeout(()=>{
                    getcategorylist()
                }, 500)
                
            }
            else{
                toast.error(res?.data?.message)
            }
        })

    }

   
    const handleImage = e => {
        const file = e.target.files[0];
        if (!file.type.match(/image.*/)) {
            alert("Invalid Image Format, supported formats are png | jpg | jpeg | webp.");
            return;
        }
        if (e.target.files.length) {
            const reader = new FileReader();
            reader.onload = function (event) {
                setcatImage(e.target.files[0])
                setImage({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: event.target.result
                });
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };


    return (<>
        <ToastContainer />
        <div id="layout-wrapper">

            <AdminHeader />
            <Sidebar />
            <div class="main-content">
                <div class="page-content">
                    <div class="container-fluid">

                        <div class="row" id="table-striped">
                            <div class="col-4">
                                <div class="card">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Add New Category</h4>

                                    </div>
                                    <div class="card-body">
                                        <div class="live-preview">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div class="row gy-4">
                                                    <div class="col-lg-12">
                                                        <div>
                                                            <label for="basiInput" class="form-label">Category Name/Title:</label><span className="text-danger">*</span>
                                                            <input type="text" class="form-control" name="cat_name"
                                                                {...register("cat_name", {
                                                                    required: true,

                                                                })}
                                                            />
                                                            {errors.cat_name && errors.cat_name.type === "required" && (
                                                                <small className="text-danger">Category Name/Title is required.</small>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div class="col-xxl-12 col-md-12">
                                                        <div>
                                                            <label for="ForminputState" class="form-label">Parent Category:</label>
                                                            <select id="ForminputState" class="form-select" data-choices data-choices-sorting="true" name='parent_cat_id' value={parentCategory} onChange={(e) => { setparentcategory(e.target.value) }} >
                                                                <option value=''>Main Category</option>
                                                                {blogcategory && blogcategory.map((item, index) => {
                                                                    return (<>
                                                                        <option value={item._id} key={index}>{item.cat_name}</option>
                                                                    </>)
                                                                })}

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-12 col-md-12">
                                                        <div  style={{ display: 'flex' }}>
                                                        <div style={{ width: '50px', height: '50px', borderRadius: '3px', border: '1px solid lightgray' }}>
                                                                {image.preview ? <>
                                                                    <img src={image.preview} style={{ width: '100%', height: '100%' }}></img>
                                                                </> : <> <img src={catImage ? constant.Image_Base_Url + catImage : constant.default_img} style={{ width: '100%', height: '100%' }}></img></>}
                                                            </div>
                                                          
                                                            <input
                                                                class="form-control"
                                                                type="file"
                                                                name='cat_image'
                                                                // onChange={(e) => setcatImage(e.target.files[0])}
                                                                onChange={(e)=>{handleImage(e)}}
                                                                accept=".jpeg,.jpg,.png"
                                                                
                                                            />
                                                        </div>
                                                    </div>



                                                    <div class="col-lg-12">
                                                        <div class="text-end">
                                                            <button type="submit" class="btn btn-primary">Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div class="col-8">
                                <div class="card">
                                    <div class="card-header">
                                        <h5>Categories Listings</h5>
                                    </div>
                                    <div class="card-body p-0" >

                                        <div class="live-preview">
                                            <table class="table table-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">S.No</th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">Category Name</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>


                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {blogcategory?.map((items, index) => {
                                                        return (<>

                                                            <tr>

                                                                <td class="fw-semibold">{index + 1}</td>
                                                                <td><div style={{width:'40px', height:"40px"}}><img  style={{width:'100%', height:"100%" , borderRadius:'5px'}} src={items?.cat_img? constant.Image_Base_Url + items?.cat_img:constant.default_img} alt={items.cat_name}></img></div></td>
                                                                <td>{items.cat_name}</td>
                                                                <td >{items?.cat_status == 0 ?
                                                                    <Badge bg="danger" onClick={()=>{changestatus(items )}}>Inactive</Badge> : items.cat_status == 1 ? <Badge bg="success" onClick={()=>{changestatus(items)}}>Active</Badge> : ''}</td>
                                                                <td className="fs-5"> <i className="mdi mdi-lead-pencil text-warning " onClick={() => { setparentcategory('');setcatId(items._id); setedititemdata(items) }}></i> <i class="mdi mdi-trash-can text-danger" onClick={() => { removecategory(items._id) }}></i> </td>

                                                            </tr>



                                                        </>)
                                                    })}
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                    {/* <div class="card-footer bg-white">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div>
                                                    <p class="mb-sm-0 mt-2">Showing 1 to 1 of 1 entries</p>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="float-sm-end">
                                                    <ul class="pagination pagination-rounded mb-sm-0"></ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default AddBlogsCategory