import React, { useEffect, useState, useRef } from "react";
import AdminHeader from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from "moment";
import Badge from 'react-bootstrap/Badge';
import constant from "../../component/Services/constant";
const Newsblogs=()=>{
    const [newsblogdata, setnewsblogdata] = useState([])
    const [edititemdata,setedititemdata] = useState({})
    const [blogImage, setblogImage] = useState()
    const navigate= useNavigate()
    let apiServices = new ApiService();
    const didMountRef = useRef(true)
    const {
        register,
        handleSubmit,
        watch,
        setError,
        getValues,
        setValue,
        formState: { errors }
    } = useForm();
    useEffect(() => {

        if (didMountRef.current) {
            getnewsblogslist()
        }
        didMountRef.current = false;
    },[])

    const getnewsblogslist = () => {
        apiServices.getnewsbloglistrequest().then(res => {
            if (res?.data?.status == "success") {
                setnewsblogdata(res.data.newsblogs)
                
            }
        }).catch((error)=>{})
    }

    const removenewsblogs = (itemid) => {
        const dataString = {
            _id: itemid
        }
        apiServices.getnewsblogsdeleterequest(dataString).then((res) => {
            if (res?.data?.status == "success") {
                toast.success("News Blogs has been Deleted successfully")
                getnewsblogslist()
            }
            else{
                toast.error(res.data.message)
            }
        }).catch((error)=>{

        })

    }
    const changestatus=(data)=>{
        const requestBody = {
            blog_id:data._id
        };
        ApiService.postData('/admin/changeblogstatus',requestBody).then(res => {
            if (res.status === "success") {
                getnewsblogslist()
            }
            else{
                toast.error(res.message)
            }
        }).catch(error => {
            console.error('Error submitting form:', error);
            // Handle error
        });
    }
    const changefeaturedblog=(data)=>{
        const requestBody = {
            blog_title: data.blog_title,
            blog_img:data.blog_img,
            blog_meta_keyword:data.blog_meta_keyword,
            blog_meta_title:data.blog_meta_title,
            blog_desc:data.blog_desc,
            blog_slug:data.blog_slug,
            blog_cat:data.blog_cat,
            blog_tag:data.blog_tag,
            blog_meta_desc:data.blog_meta_desc,
            blog_short_desc:data.blog_short_desc,
            blog_written_by:data.blog_written_by,
            blog_featured:data.blog_featured==1?0:data.blog_featured==0?1:0,
            blog_id:data._id
        };
        apiServices.getnewsblogrequest(requestBody).then(res => {
            if (res.data.status === "success") {
                getnewsblogslist()
            }
        }).catch(error => {
            console.error('Error submitting form:', error);
            // Handle error
        });
    }

   
    return(<>
    <ToastContainer />
            <div id="layout-wrapper">

                <AdminHeader />
                <Sidebar />

                <div class="main-content">

                    <div class="page-content">
                        <div class="container-fluid">


                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <div>
                                        <h4 class="mb-sm-0">News Blogs</h4>
                                        <ol class="breadcrumb mt-2">
                                                <li class="breadcrumb-item"><a href="/csadmin/dashboard">Dashboard</a></li>
                                                <li class="breadcrumb-item active">News Blogs</li>
                                            </ol>
                                        </div>
                                      
                                        <div class="page-title-right">
                                        <div class="col-lg-12">
                                                            <div class="text-end">
                                                                <button  class="btn btn-primary" onClick={()=>{navigate('/csadmin/add-new-blogs')}}>Add New</button>
                                                            </div>
                                                        </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">

                                        <div class="card-body">
                                            <div class="live-preview">
                                                {newsblogdata && newsblogdata.length>0 ?<>
                                                
                                                    <table class="table table-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">S.No</th>
                                                            <th scope="col">Image</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Featured</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Created At</th>
                                                            <th scope="col">Action</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                  
                                                        {newsblogdata?.map((items, index) => {
                                                            return (<>

                                                                <tr>

                                                                    <td class="fw-semibold">{index + 1}</td>
                                                                    <td><div style={{width:'60px', height:"60px"}}><img  style={{width:'100%', height:"100%" , borderRadius:'5px'}} src={items?.blog_img!==''? constant.Image_Base_Url + items?.blog_img:constant.default_img} alt={items.blog_title}></img></div></td>
                                                                    <td>{items?.blog_title}</td>
                                                                    <td onClick={()=>{changefeaturedblog(items)}}>{items?.blog_featured==0?<i class="ri  ri-star-line"></i>:items.blog_featured==1?<i class="ri ri-star-fill"></i>:''}</td>
                                                                    <td >{items?.blog_status == 0 ?
                                                                    <Badge bg="danger" onClick={()=>{changestatus(items )}}>Inactive</Badge> : items.blog_status == 1 ? <Badge bg="success" onClick={()=>{changestatus(items)}}>Active</Badge> : ''}</td>
                                                                    <td>{moment(items?.createdAt).format(`LL`)}</td>
                                                                    <td className="  fs-5"> <i className="mdi mdi-lead-pencil text-warning" onClick={()=>{navigate(`/csadmin/add-new-blogs/${items.blog_slug}`)}}></i> <i class="mdi mdi-trash-can text-danger" onClick={()=>{removenewsblogs(items)}}></i> </td>
                                                                    
                                                                </tr>



                                                            </>)
                                                        })}


                                                    </tbody>
                                                </table>
                                                </>:<><h6>No Blogs Found</h6></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div >

            </div >
            {/* <SweetAlert warning confirmBtnCssClass='alertpop' title={` Are you sure ? you want to delete the newsletter record `} confirmBtnText='Confirm'
                cancelBtnText="Cancel"
                show={show} onConfirm={Confirm} onCancel={Cancelalert} btnSize="md" showCancel
                cancelBtnBsStyle="danger"
            >
            </SweetAlert> */}
    
    </>)
}
export default Newsblogs