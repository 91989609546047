
const API_URL ="https://crm.proptrail.au/"
// const API_URL='http://localhost:8000/'
const CATEGORIES = [];
const themesetting = {};
const Image_Base_Url='https://crm.proptrail.au/uploads/'
// blob:http://localhost:5001/52f378fd-8f1b-4c6a-970d-852c8dbddf95
// const Image_Base_Url='http://localhost:8000/uploads/'
const Page_Base_URL='https://crm.proptrail.au/'
const default_img='/img/default_img.png'
export default { API_URL, CATEGORIES, themesetting, Image_Base_Url , default_img,Page_Base_URL};

