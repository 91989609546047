import AdminFooter from "../../component/Footer"
import AdminHeader from "../../component/Header"
import Sidebar from "../../component/Sidebar"
import { ToastContainer, toast } from 'react-toastify';
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import constant from "../../component/Services/constant";



const AddNewBlog = () => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm();
    let apiServices = new ApiService();
    const didMountRef = useRef(true)
    const { slug } = useParams()
    const [blogImage, setblogImage] = useState('')
    console.log(blogImage, 'blogImage')
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedtags, setselectedtags] = useState([])
    const [isEditorReady, setIsEditorReady] = useState(false);
    const [categorydata, setcategorydata] = useState([])
    const [tagdata, settagdata] = useState([])
    const [error, seterror] = useState({})
    const [blogDescrip, setblogDescrip] = useState('');
    const editorRef = useRef(null);

    useEffect(() => {

        if (didMountRef.current) {
            getcategorylist()
            gettagslist()
            if (slug) {
                getnewsblogdetail()
            }
        }
        didMountRef.current = false;

    })
    const [image, setImage] = useState({ preview: "", raw: "" });
    const handleImage = e => {
        const file = e.target.files[0];
        if (!file.type.match(/image.*/)) {
            alert("Invalid Image Format, supported formats are png | jpg | jpeg | webp.");
            return;
        }
        if (e.target.files.length) {
            const reader = new FileReader();
            reader.onload = function (event) {
                setblogImage(e.target.files[0])
                setImage({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: event.target.result
                });
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };


    const onSubmit = (data) => {
        seterror({})
        const formData = new FormData();
        formData.append('blog_title', data.blog_title);
        formData.append('blog_written_by', data.blog_written_by);
        formData.append('blog_short_desc', data.blog_short_desc);
        if (data.blog_meta_title && data.blog_meta_title.length < 70) {
            formData.append('blog_meta_title', data.blog_meta_title);
        }
        else if (data.blog_meta_title && data.blog_meta_title.length > 70) {
            seterror({
                type: "blog_meta_title",
                message: "Meta Title should not be greater than 70 characters",
            })
            return
        }
        if (data.blog_meta_keyword && data.blog_meta_keyword.length < 160) {
            formData.append('blog_meta_keyword', data.blog_meta_keyword);
        }
        else if (data.blog_meta_keyword && data.blog_meta_keyword.length > 160) {
            seterror({
                type: "blog_meta_keyword",
                message: "Meta Keyword should not be greater than 160 characters",
            })
            return
        }
        if (data.blog_meta_desc && data.blog_meta_desc.length < 250) {
            formData.append('blog_meta_desc', data.blog_meta_desc);
        }
        else if (data.blog_meta_desc && data.blog_meta_desc.length > 250) {
            seterror({
                type: "blog_meta_desc",
                message: "Meta Description should not be greater than 250 characters",
            })
            return
        }

        if (selectedtags && selectedtags !== null && selectedtags.length > 0) {
            selectedtags.forEach(item => {
                formData.append('blog_tag', item);
            });
        }
        if (selectedItems && selectedItems !== null && selectedItems.length > 0) {
            selectedItems.forEach(item => {
                formData.append('blog_cat', item);
            });
        }
        if (blogDescrip && blogDescrip !== null && blogDescrip !== '') {
            formData.append('blog_desc', blogDescrip);
        }
        if (blogImage && blogImage !== '')
            formData.append('blog_img', blogImage);
        if (slug && slug !== null)
            formData.append('blog_slug', slug);
        if (selectedItems.length == 0) {
            seterror({
                type: 'emptycategory',
                message: 'Please Select atleast one categroy before to proceed'
            })
            return
        }

        apiServices.getnewsblogrequest(formData).then(res => {
            if (res.data.status === "success") {
                toast.success(res?.data?.message);
                setSelectedItems([])
                setselectedtags([])
                setblogDescrip('')
                reset();
            }
            else if (res.data.status === "error") {

                toast.error(res?.data?.message);
            }
        }).catch(error => {
            console.error('Error submitting form:', error);
            // Handle error
        });
    };
    const handleCheckboxChange = (item) => {
        setSelectedItems(prevSelectedItems => {
            if (prevSelectedItems.includes(item?._id)) {
                return prevSelectedItems.filter(selectedItem => selectedItem !== item?._id);
            } else {
                return [...prevSelectedItems, item?._id];
            }
        });


    };
    const handleTagCheckboxChange = (item, type) => {
        if (!item || !item._id) return;
        setselectedtags(prevSelectedItems => {
            if (prevSelectedItems.includes(item._id)) {
                return prevSelectedItems.filter(selectedItem => selectedItem !== item._id);
            } else {
                return [...prevSelectedItems, item._id];
            }
        });
    };
    useEffect(() => {
        if (isEditorReady) {
            if (editorRef.current && blogDescrip) {
                editorRef.current.setData(blogDescrip);

            }
        }
    }, [isEditorReady, blogDescrip]);

    const getcategorylist = () => {
        apiServices.getnewsblogcategorylistrequest().then(res => {
            if (res?.data?.status == "success") {
                setcategorydata(res.data.categories)
            }
        }).catch((error) => { })
    }
    const gettagslist = () => {
        apiServices.getnewsblogtagslistrequest().then(res => {
            if (res?.data?.status == "success") {
                settagdata(res.data.tags)
            }
        })
    }

    const getnewsblogdetail = () => {
        const dataString = {
            blog_slug: slug
        }
        apiServices.getnewsblogdetailrequest(dataString).then((res) => {
            if (res.data.status == 'success') {
                const data = res.data.newsblogs

                setValue("blog_title", data?.blog_title);
                setValue("blog_written_by", data?.blog_written_by);
                setValue("blog_short_desc", data?.blog_short_desc);
                setValue("blog_meta_desc", data?.blog_meta_desc);
                setValue("blog_meta_title", data?.blog_meta_title);
                setValue("blog_meta_keyword", data?.blog_meta_keyword);
                setSelectedItems(data?.blog_cat)
                setselectedtags(data?.blog_tag)
                setblogImage(data?.blog_img)
                if (data?.blog_desc !== undefined && data?.blog_desc !== null) {
                    setblogDescrip(data?.blog_desc);
                }


            }
        }).catch((error) => { })
    }

    useEffect(() => {
        const loadScript = (src, callback) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = callback;
            document.body.appendChild(script);
        };

        const initializeCKEditor = () => {
            const editorElement = document.getElementById('editor');
            if (editorElement && !editorRef.current) {
                window.CKEDITOR.replace(editorElement);
                editorRef.current = window.CKEDITOR.instances[editorElement.id];

                editorRef.current.on('change', () => {
                    const data = editorRef.current.getData();
                    setblogDescrip(data);
                });
                setIsEditorReady(true);
            }
        };

        loadScript('https://cdn.ckeditor.com/4.12.1/full-all/ckeditor.js', () => {
            const checkCKEditorLoaded = setInterval(() => {
                if (window.CKEDITOR) {
                    clearInterval(checkCKEditorLoaded);
                    initializeCKEditor();
                }
            }, 100);

            return () => clearInterval(checkCKEditorLoaded);
        });

        return () => {
            if (editorRef.current) {
                editorRef.current.destroy();
                editorRef.current = null;
            }
        };
    }, []);


    return (<>
        <ToastContainer />
        <div id="layout-wrapper">

            <AdminHeader />
            <Sidebar />

            <div class="main-content">

                <div class="page-content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Add New</h4>
                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                            <li class="breadcrumb-item"><a href="/csadmin/dashboard">Dashboard</a></li>
                                            <li class="breadcrumb-item active">Add New</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {error && error.type == 'emptycategory' ? <Alert variant={'danger'}>
                                {error.message}
                            </Alert> : ""}

                            <div class="row">
                                <div class="col-lg-7">
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">Add New</h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="live-preview">
                                                <div class="row gy-4">
                                                    <div class="col-lg-7 col-xxl-7">
                                                        <div>
                                                            <label for="basiInput" class="form-label" >Blog Name/Title<span>*</span></label>
                                                            <input type="text" class="form-control" name='blog_title'  {...register("blog_title", {
                                                                required: true,

                                                            })} />
                                                            {errors.blog_name && errors.blog_name.type === "required" && (
                                                                <small className="text-danger">Blog Name/Title is required.</small>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div class="col-xxl-5 col-md-5">
                                                        <div>
                                                            <label for="basiInput" class="form-label">Blog Written By:</label>
                                                            <input type="text" class="form-control" name="blog_written_by"  {...register("blog_written_by", {
                                                                // required: true,

                                                            })} />

                                                        </div>
                                                    </div>
                                                    <div class="col-xxl-12 col-md-12">
                                                        <div>
                                                            <label for="basiInput" class="form-label">Short description</label>
                                                            <textarea class="form-control" id="exampleFormControlTextarea5" rows="3" name="blog_short_desc" {...register("blog_short_desc", {
                                                            })}></textarea>



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 1 end */}
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">Description</h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="live-preview">
                                                <div class="row gy-4">

                                                    <div class="col-xxl-12 col-md-12">
                                                        <textarea className="ckeditor-classic" id="editor"></textarea>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 2 end */}
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">SEO - Meta Tags</h4>
                                        </div>
                                        <p style={{ fontSize: '14px' }} className="m-4">Define blog meta title, meta keywords and meta description to list your page in search engines.</p>
                                        <div class="card-body">
                                            <div class="live-preview">
                                                <div class="row gy-4">

                                                    <div class="col-xxl-12 col-md-12">
                                                        <div>
                                                            <label for="basiInput" class="form-label" >Meta Title<span className="text-danger">*</span></label>
                                                            <input type="text" class="form-control" name='blog_meta_title'  {...register("blog_meta_title", {
                                                                // required: true,

                                                            })} />
                                                            <p style={{ fontSize: '12px' }}>Max length 70 characters</p>
                                                            {/* {errors.meta_title && errors.meta_title.type === "required" && (
                                                                <small className="text-danger">Meta Title is required.</small>
                                                            )} */}
                                                            {error && error.type == 'blog_meta_title' && (
                                                                <small className="text-danger">{error.message}</small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div class="col-xxl-12 col-md-12">
                                                        <div>
                                                            <label for="basiInput" class="form-label" >Meta Keyword</label>
                                                            <input type="text" class="form-control" name='blog_meta_keyword'  {...register("blog_meta_keyword", {


                                                            })} />
                                                            <p style={{ fontSize: '12px' }}>Max length 160 characters</p>
                                                            {error && error.type == 'blog_meta_keyword' && (
                                                                <small className="text-danger">{error.message}</small>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div class="col-xxl-12 col-md-12">
                                                        <div>
                                                            <label for="basiInput" class="form-label" >Meta Description</label>
                                                            <input type="text" class="form-control" name='blog_meta_desc'  {...register("blog_meta_desc", {


                                                            })} />
                                                            <p style={{ fontSize: '12px' }}>Max length 250 characters</p>
                                                            {error && error.type == 'blog_meta_desc' && (
                                                                <small className="text-danger">{error.message}</small>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5">
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">Publish</h4>
                                        </div>

                                        <div class="card-body">
                                            <div class="live-preview">
                                                <div class="row gy-4">
                                                    <div class="col-lg-12">
                                                        <div class="text-end">
                                                            <button type="submit" class="btn btn-primary">Publish</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">Categories:<span className="text-danger">*</span></h4>
                                        </div>
                                        <p style={{ fontSize: '12px' }} className="m-4">Select category in which you want to display this blog. You can also select multiple categories for this blog.</p>
                                        <div class="card-body" style={{ height: '250px', overflowX: 'hidden', border: '1px solid #eee' }}>
                                            {categorydata && categorydata.length > 0 ? <>
                                                <div class="live-preview">
                                                    <div class="row gy-4">
                                                        <div class="col-lg-12">
                                                            {categorydata.map((item, index) => {
                                                                return (<>
                                                                    <div class="form-check" key={index} >
                                                                        <input class="form-check-input" type="checkbox" id="formCheck1" checked={selectedItems.includes(item?._id)}
                                                                            onChange={() => handleCheckboxChange(item, 'category')} />
                                                                        <label class="form-label" >
                                                                            {item?.cat_name}
                                                                        </label>
                                                                    </div>

                                                                </>)
                                                            })}

                                                        </div>

                                                    </div>
                                                </div>


                                            </> : ''}

                                        </div>
                                        <div class="card-footer bg-white"><a href="/csadmin/news-blogs/categories" target="_blank" style={{ textDecoration: 'none' }}>+ Add New Categories</a></div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">Tags:<span className="text-danger">*</span></h4>
                                        </div>
                                        <div class="card-body" style={{ height: '250px', overflowX: 'hidden', border: '1px solid #eee' }}>
                                            {tagdata && tagdata.length > 0 ? <>
                                                <div class="live-preview">
                                                    <div class="row gy-4">
                                                        <div class="col-lg-12">
                                                            {tagdata.map((item, index) => {
                                                                console.log(selectedtags, 'selectedtagsselectedtagsselectedtags')
                                                                return (<>
                                                                    <div class="form-check" key={index} >
                                                                        <input class="form-check-input" type="checkbox" id="formCheck1" checked={selectedtags.includes(item?._id)} onChange={() => handleTagCheckboxChange(item, 'tag')} />
                                                                        <label class="form-label" >
                                                                            {item?.tag_name}
                                                                        </label>
                                                                    </div>

                                                                </>)
                                                            })}

                                                        </div>

                                                    </div>
                                                </div>


                                            </> : ''}

                                        </div>
                                        <div class="card-footer bg-white"><a href="/csadmin/news-blogs/tags" target="_blank" style={{ textDecoration: 'none' }}>+ Add New Tags</a></div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">Featured Image:</h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="live-preview">
                                                <div class="row gy-4">
                                                    <div class="col-lg-12">
                                                        <div style={{ display: 'flex' }}>

                                                            <div style={{ width: '50px', height: '50px', borderRadius: '3px', border: '1px solid lightgray' }}>
                                                                {image.preview ? <>
                                                                    <img src={image.preview} style={{ width: '100%', height: '100%' }}></img>
                                                                </> : <> <img src={blogImage !== '' ? constant.Image_Base_Url + blogImage : constant.default_img} style={{ width: '100%', height: '100%' }}></img></>}
                                                            </div>


                                                            <input
                                                                class="form-control"
                                                                type="file"
                                                                onChange={(e) => { handleImage(e) }}
                                                                // onChange={(e) => setblogImage(e.target.files[0])}
                                                                accept=".jpeg,.jpg,.png"

                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div >

        </div >
    </>)
}

export default AddNewBlog