import axios from 'axios';
import constant from './constant';

const getadminloginUrl = "admin/getadminlogin"
const getdashboarddefaultUrl = "admin/dashboard/savedefaultvalues"
const getyrwiseddefaultUrl = "admin/dashboard/saveyearwisedefaultvalues"
const getdefaultVyrwisedUrl = "admin/dashboard/getyearwisedefaultvalues"
const getfinddefaultVyrwisedUrl = "admin/dashboard/getfindyrwisedefaultvalues"
const getdefaultvalueUrl="admin/dashboard/getdefaultvalues"
const getadmingeneralsettingUrl="admin/savegeneraladmindata"
const getadminsocialsettingUrl="admin/savesettingadmindata"
const getadminsettingdataUrl="admin/getadminsettingdata"
const getnewsletterdataUrl="enquiry/getnewsletterdata"
const getnewsletterdeletedUrl="enquiry/deletenewsletter"
const getnewsblogcatUrl='admin/addblogscategory'
const getnewsblogcatlistUrl='admin/getblogscategory'
const getnewsblogstagdeleteUrl='admin/deleteblogstags'
const getnewsblogscatdeleteUrl='admin/deleteblogscategory'
const getnewsblogtagUrl='admin/addblogstag'
const getnewsblogtaglistUrl='admin/getblogstags'
const getnewsblogUrl='admin/addnewsblogs'
const getnewsbloglistUrl='admin/getnewsblogs'
const getnewsblogsdeleteUrl='admin/deletenewsblogs'
const getnewsblogsdetailUrl='admin/getnewsblogdetail'
const getfeaturednewsbloglistUrl='admin/getfeaturednewsblogs'
const getfaqpostUrl ='admin/addfaqdata'
const getfaqlistUrl ='admin/getfaqdata'
const getfaqdeleteUrl ='admin/deletefaq'
const getfaqdetailUrl ='admin/getfaqdetailUrl'
const getappearancefooterUrl ='admin/addfooterdata'
const getappearancefooterdataUrl = 'admin/getfooterdata'
const getaddnewpagesUrl = 'admin/addnewpagedata'
const getallnewpagesUrl ='admin/getpagesdata'
const getnewpagedeleteUrl ='admin/deletepages'
const getnewpagedetailUrl ='admin/getPagedetail'
const getsearchpageUrl='admin/getsearchpageslist'
const getuserdataUrl = 'admin/getuserlistdata'
const getuserdetailUrl = 'admin/getuserdetail'

const client = axios.create({
  baseURL: constant.API_URL,
});

export class ApiService {
  getadminloginPostRequest(request) {
    return client.post(getadminloginUrl, request)
  }
  getadmingensettingPostRequest(request) {
    return client.post(getadmingeneralsettingUrl, request)
  }
  getadmingensociasettingPostRequest(request) {
    return client.post(getadminsocialsettingUrl, request)
  }
  getadminsettingdataGetRequest(request) {
    return client.get(getadminsettingdataUrl)
  }
  getdashboarddefaultvalues(request) {
    return client.post(getdashboarddefaultUrl, request)
  }
  postyearwisedefaultvalues(request) {
    return client.post(getyrwiseddefaultUrl, request)
  }
  getdefaultvaluesyearwise(request) {
    return client.post(getdefaultVyrwisedUrl, request)
  }
  getfinddefaultVyrwise(request) {
    return client.post(getfinddefaultVyrwisedUrl, request)
  }
  getdefaultvaluesrequest(){
    return client.get(getdefaultvalueUrl)
  }
  getnewsletterdatarequest(){
    return client.get(getnewsletterdataUrl)
  }
  getnewsletterdeleterequest(request){
    return client.post(getnewsletterdeletedUrl, request)
  }
  getnewsblogcategoryrequest(request){
    return client.post(getnewsblogcatUrl, request)
  }
  getnewsblogcategorylistrequest(){
    return client.get(getnewsblogcatlistUrl)
  }
  getnewsblogsdeletecatrequest(request){
    return client.post(getnewsblogscatdeleteUrl, request)
  }
  getnewsblogtagsrequest(request){
    return client.post(getnewsblogtagUrl, request)
  }
  getnewsblogtagslistrequest(){
    return client.get(getnewsblogtaglistUrl)
  }
  getnewsblogstagdeleterequest(request){
    return client.post(getnewsblogstagdeleteUrl, request)
  }

  getnewsblogrequest(request){
    return client.post(getnewsblogUrl, request)
  }
  getnewsbloglistrequest(){
    return client.get(getnewsbloglistUrl)
  }

  getfeaturednewsbloglistrequest(){
    return client.get(getfeaturednewsbloglistUrl)
  }
  getnewsblogsdeleterequest(request){
    return client.post(getnewsblogsdeleteUrl, request)
  }
  getnewsblogdetailrequest(request){
    return client.post(getnewsblogsdetailUrl, request)
  }
  getfaqaddrequest(request){
    return client.post(getfaqpostUrl, request)
  }

  getfaqlistrequest(){
    return client.get(getfaqlistUrl)
  }
  getfaqdeleterequest(request){
    return client.post(getfaqdeleteUrl , request)
  }
  getfaqdetailrequest(request){
    return client.post(getfaqdetailUrl , request)
  }

  getappearancefooterupdaterequest(request){
    return client.post(getappearancefooterUrl , request)
  }
  getfooterdatarequest(){
    return client.get(getappearancefooterdataUrl)
  }
  getaddnewpagesrequest(request){
    return client.post(getaddnewpagesUrl , request)
  }
  getallnewpagesrequest(){
    return client.get(getallnewpagesUrl)
  }
  getnewpagedeleterequest(request){
    return client.post(getnewpagedeleteUrl, request)
  }
  getnewpagedetailrequest(request){
    return client.post(getnewpagedetailUrl, request)
  }
  getsearchpagedatarequest(request){
    return client.get(`admin/getsearchpageslist?searchString=${request}`)
  }
  getuserdatarequest(){
    return client.get(getuserdataUrl)
  }
  getuserdetailrequest(request){
    return client.post(getuserdetailUrl , request)
  }
  getusersearchdatarequest(request){
    return client.get(`admin/getusersearchlistdata?searchQuery=${request}`)
  }

  static async fetchData(url) {
    try {
      const response = await client.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async postData(url,data) {
    try {
      const response = await client.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
 
 
  
}
