import React, { useEffect, useState, useRef } from "react";
import AdminHeader from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Badge from 'react-bootstrap/Badge';
import constant from "../../component/Services/constant";
const Customer = () => {
    const [Userdata, setUserdata] = useState([])
    const [showResetBtn, setShowRestBtn] = useState(false)
    const [Query, setQuery] = useState('')
    const navigate = useNavigate()
    let apiServices = new ApiService();
    const didMountRef = useRef(true)
    const {
        register,
        handleSubmit,
        watch,
        setError,
        getValues,
        setValue,
        formState: { errors }
    } = useForm();
    useEffect(() => {

        if (didMountRef.current) {
            getalluserlist()
        }
        didMountRef.current = false;
    }, [])

    const getalluserlist = () => {
        apiServices.getuserdatarequest().then(res => {
            if (res?.data?.status == "success") {
                setUserdata(res.data.UserData)
            }
        }).catch((error) => { })
    }

    const handlesearchSubmit = () => {
        if (Query.length > 2) {
            apiServices.getusersearchdatarequest(Query).then((res) => {
                if (res?.data?.status == "success") {
                    setUserdata(res.data.UserData)
                    setShowRestBtn(true)

                }
                else {
                    toast.error(res.data.message)
                }
            }).catch((error) => { })
        }
    }

    const changestatus = (data) => {
        const requestBody = {
            user_id: data._id
        };
        ApiService.postData('/admin/changeuserstatus', requestBody).then(res => {
            if (res.status === "success") {
                getalluserlist()
            }
            else {
                toast.error(res.data.message)
            }
        }).catch(error => {
            console.error('Error submitting form:', error);
            // Handle error
        });
    }




    const onResetclick = () => {
        setQuery('')
        getalluserlist()
        setTimeout(() => {
            setShowRestBtn(false)
        }, 2000)
    }


    const removecustomers = (itemid) => {
        const dataString = {
            user_id: itemid
        }
        const confirmed = window.confirm("Are you sure you want to Delete this User");
        if (confirmed) {
            ApiService.postData('/admin/deleteUser', dataString).then((res) => {
                if (res?.status == "success") {
                    toast.success(res.message)
                    setTimeout(() => {
                        getalluserlist()
                    }, 500)
                }
                else {
                    toast.error(res?.message)
                }
            }).catch((error) => { })
        }


    }

    return (<>
        <ToastContainer />
        <div id="layout-wrapper">
            <AdminHeader />
            <Sidebar />
            <div class="main-content">
                <div class="page-content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <div>
                                        <h4 class="mb-sm-0">Manage Customers</h4>
                                        <ol class="breadcrumb mt-2">
                                            <li class="breadcrumb-item"><a href="/csadmin/dashboard">Dashboard</a></li>
                                            <li class="breadcrumb-item active">Manage Customers</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">

                                    <div class="card-body">
                                        <div class="input-group">
                                            <input type="text" class="form-control form-control-lg" placeholder="Search" aria-label="Example text with two button addons" value={Query} onChange={(e) => { setQuery(e.target.value); setShowRestBtn(false) }} />
                                            <button class="btn btn-primary" type="button" onClick={() => { handlesearchSubmit() }}>Search</button>
                                            {showResetBtn ? <>
                                                <button class="btn btn-success" type="button" onClick={() => { onResetclick() }}>Reset</button>
                                            </> : ''}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">

                                    <div class="card-body">
                                        <div class="live-preview">
                                            {Userdata && Userdata.length > 0 ? <>

                                                <table class="table table-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">S.No</th>
                                                            <th scope="col">Customer Name</th>
                                                            <th scope="col">Mobile</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Total Properties</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Registration Date</th>
                                                            <th scope="col">Action</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {Userdata?.map((items, index) => {
                                                            return (<>

                                                                <tr>
                                                                    <td class="fw-semibold">{index + 1}</td>
                                                                    <td>{items.user_name}</td>
                                                                    <td>{items.user_mobileno}</td>
                                                                    <td>{items.user_email}</td>
                                                                    <td>{items.property_count}</td>
                                                                    <td >{items?.user_admin_status == 0 ?
                                                                        <Badge bg="danger" onClick={() => { changestatus(items) }}>Inactive</Badge> : items.user_admin_status == 1 ? <Badge bg="success" onClick={() => { changestatus(items) }}>Active</Badge> : ''}</td>
                                                                    <td>{moment(items?.created_at).format(`LL`)}</td>
                                                                    <td className="  fs-5"> <i className="text-warning mdi mdi-lead-pencil" onClick={() => { navigate(`/csadmin/customer/${items._id}`) }}></i>  <i class=" text-danger mdi mdi-trash-can" onClick={() => { removecustomers(items) }}></i> </td>


                                                                </tr>
                                                            </>)
                                                        })}


                                                    </tbody>
                                                </table>
                                            </> : <><h6>No User Found</h6></>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div >

        </div >


    </>)
}
export default Customer